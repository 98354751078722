import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import SayHi from "../components/SayHi"
import Link from "../components/Link"
import { Box } from "../components/Box"
import { Paragraph, Heading1,  Heading2, TextSmall } from "../components/Typography"
import List from "../components/List/List"
import OrderedList from "../components/List/OrderedList"
import { Separator } from "../components/Separator"

import Talker from "../content/images/speech/talker.gif"
const PinBanner = styled(Box)`
  display: flex;
  flex-direction: row;
  @media (max-width: 414px) {
    flex-direction: column;
  }
`

const PinPic = styled.div`
  text-align:center;
  margin-top:10px;
  img{
    
  }
  @media (max-width: 414px) {
    img{
      margin:20px auto;
    }
  }
`
const SHeading2 = styled(Heading2)`
  margin:0;
`


const Speech = ({ data }) => {
  return (
    <Layout>
      <Box maxWidth="640px" marginTop={20} element="section">
        <SEO title="演讲" />
        <Heading1> <span role="img">👏</span> 我的分享</Heading1>
        <PinBanner element="section"  >
       
        <Paragraph>
          我不太擅长公众表达，对外分享和演讲并不多，以下是整理过去几年的内容，希望能对你有所启发。
        </Paragraph>
        <PinPic><img src={Talker} alt="avenrizheng" width="250" /></PinPic>
        </PinBanner>
        <Box marginTop={20} element="section">
          <SHeading2>DesignOps at Tencent - IxDC 新时代的设计力峰会</SHeading2>
          <TextSmall>2021年11月19日 - 线上</TextSmall>
          <PinPic>
          <Img fluid={data.ixdc.childImageSharp.fluid}  alt="avenrizheng at IXDC"/>
          </PinPic>
          <Separator />
          <Paragraph>
          我分享 DesignOps 模式作为支持精益与敏捷设计的管理工具，将为产品设计带来三大核心价值：
          <OrderedList>
          <li>设计价值最大化</li>
          <li>体验设计自动化</li>
          <li>设计研发一体化</li>
          </OrderedList>
          </Paragraph>
          <Paragraph>
            想了解分享内容可查看的我的{' '}<Link  underlined href={data.designops.publicURL} target="_blank">Keynote</Link>
            
          </Paragraph>
        </Box>
        <Box marginTop={20} element="section">
          <SHeading2>敏捷设计，云端协作 - Techo Park 开发者大会</SHeading2>
          <TextSmall>2020年12月20日 - 北京·751 D·PARK</TextSmall>
          <PinPic>
          <Img fluid={data.techo.childImageSharp.fluid}  alt="avenrizheng at Techo"/>
          </PinPic>
          <Separator />
          <Paragraph>
          我分享 DesignOps 的理念以及敏捷设计和未来的协作：
          <OrderedList>
          <li>敏捷设计的实践</li>
          <li>腾讯 DesignOps 的工具链；</li>
          <li>未来设计和研发更紧密结合的协作模式</li>
          </OrderedList>
          </Paragraph>
          <Paragraph>
            想了解分享内容可查看的我的{' '}<Link  underlined href={data.agiedesign.publicURL} target="_blank">Keynote</Link>{' '}和{' '}<Link underlined href="https://techo.cloud.tencent.com/2020/index.html#/live?subSeminarId=2011284008188282" target="_blank">现场回放</Link>
            
          </Paragraph>
        </Box>
        <Box marginTop={20} element="section">
          <SHeading2>第一现场 - CDC Talk</SHeading2>
          <TextSmall>2019年12月7日 - 深圳·腾讯滨海大厦多功能厅</TextSmall>
          <PinPic>
          <Img fluid={data.cdctalk.childImageSharp.fluid}  alt="avenrizheng at cdctalk"/>
          </PinPic>
          <Separator />
          <Paragraph>
          我分享前端开发这个职业带给我愉悦的几个时刻：
          <OrderedList>
          <li>在产品诞生时体会到亲手做出成品的成就感；</li>
          <li>在产品出现问题时体会到身上背负的责任感；</li>
          <li>用户使用自己产品时体会到的幸福感；</li>
          <li>为自己和团队能挑战技术难题而感到的自豪感。</li>
          </OrderedList>
          </Paragraph>
          <Paragraph>
            第一次以 Talk 形式做分享，因为事前准备和现场状况的问题，感觉并没有很好表达出我想说的故事，也许以后可以以文字形式分享。
          </Paragraph>
          <Paragraph>
            想了解分享内容可查看的我的{' '}<Link  underlined href={data.primaryscene.publicURL} target="_blank">Keynote</Link>{' '}和{' '}<Link underlined href="https://v.qq.com/x/page/h3037a5n5qh.html" target="_blank">现场回放</Link>
            
          </Paragraph>
        </Box>
        <Box marginTop={20} element="section">
        <SHeading2>前端体验中的设计原理 - GMTC 北京</SHeading2>
          <TextSmall>2018年6月22日 - 北京·国际会议中心</TextSmall>
          <PinPic><Img fluid={data.gmtc.childImageSharp.fluid}  alt="avenrizheng at gmtc"/>
          </PinPic>
          <Separator />
          <Paragraph>
            我入行时，受 Jeremy Keith 的 <Link  underlined href="https://fronteers.nl/congres/2010/sessions/the-design-of-html5-jeremy-keith" target="_blank">HTML5中的设计原理</Link> 影响，希望自己能把理解的和遵循的一些最佳实践精炼总结为几个原则：
            <OrderedList>
          <li>健壮性原则 - 永远不要相信用户的输入</li>
          <li>不确定性 - 时刻准备为用户兜底</li>
          <li>渐进增强 - 依赖可靠技术，保证核心内容</li>
          <li>通用性设计 - 对所有人都友好</li>
          </OrderedList>
          </Paragraph>
          <Paragraph>
            想了解分享内容可查看的我的{' '}<Link  underlined href={data.principles.publicURL} target="_blank">Keynote</Link>{' '}和{' '}<Link underlined href="https://time.geekbang.org/dailylesson/detail/100016431" target="_blank">现场回放</Link>
            
          </Paragraph>
          
        </Box>
        <Box marginTop={20} element="section">
        <SHeading2>重温网站重构 - Webrebuild</SHeading2>
          <TextSmall>2010年8月21日 - 深圳·腾讯大厦多功能厅</TextSmall>
          <PinPic><Img fluid={data.webrebuild.childImageSharp.fluid}  alt="avenrizheng at webrebuild"/>
          </PinPic>
          <Separator />
          <Paragraph>
          <Link  underlined href="https://book.douban.com/subject/1230451/" target="_blank">《网站重构》</Link>，是 Designing with Web Standards 不太准确的翻译，直到今天像腾讯、迅雷、小米等等很多大型互联网公司还会有网页重构工程师的岗位，网站重构简单理解就是从 Web 1.0 时期的标签滥用，浏览器私有属性过渡到 Web 2.0 时期以 W3C 及浏览器联盟为主的行业标准开发模式。
          </Paragraph>
          <Paragraph>
          内容过于久远，如果你仍想了解内容可查看的我的{' '}<Link  underlined href={data.webstandards.publicURL} target="_blank">Keynote</Link>
            
          </Paragraph>
          
        </Box>
        <Box element="section" marginTop={20} marginBottom={32}>
          <SayHi />
        </Box>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query {
    designops: file(relativePath: { eq: "talks/designops-at-tencent.pdf" }) {
      publicURL
    }
    agiedesign: file(relativePath: { eq: "talks/agiedesign.pdf" }) {
      publicURL
    }
    principles: file(relativePath: { eq: "talks/principlesoffront-endexperience.pdf" }) {
      publicURL
    }
    webstandards: file(relativePath: { eq: "talks/reviewsofdesigningwithwebstandards.pdf" }) {
      publicURL
    }
    primaryscene: file(
      relativePath: { eq: "talks/primaryscene.pdf" }
    ) {
      publicURL
    }
    ixdc: file(relativePath: { eq: "images/speech/ixdc.png" }) {
      childImageSharp {
        fluid(maxWidth: 640,quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    techo: file(relativePath: { eq: "images/speech/techo.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 640,quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cdctalk: file(relativePath: { eq: "images/speech/cdctalk.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 640,quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    gmtc: file(relativePath: { eq: "images/speech/gmtc.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 640,quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    webrebuild: file(relativePath: { eq: "images/speech/webrebuild.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 640,quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default Speech
